import { FacetT, FieldValue, FieldValueWrapper, Result } from "@elastic/react-search-ui";
import { IResponse, IResponseState, ISearchResponse, ISearchResultHit } from "../models/dataModel";
import buildStateFacets from "./buildStateFacets";

function buildTotalPages(resultsPerPage: number | undefined, totalResults: number): number {
  if (!resultsPerPage) return 0;
  if (totalResults === 0) return 1;
  return Math.ceil(totalResults / resultsPerPage);
}

function buildTotalResults(hits: ISearchResponse): number {
  return hits.total.value;
}

function getHighlight(hit: ISearchResultHit, fieldName: string): string | undefined {
  if (!hit.highlight || !hit.highlight[fieldName] || hit.highlight[fieldName].length < 1) {
    return;
  }

  return hit.highlight[fieldName][0];
}

function buildResults(hits: ISearchResultHit[], linkUrl: string): Result[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const addEachKeyValueToObject = (prev, current, index, array) => ({
    ...prev,
    [current[0]]: current[1],
  });

  const toObject = (value: FieldValue, snippet?: string): FieldValueWrapper => {
    return { raw: value, ...(snippet && { snippet }) };
  };

  const getFieldValue = (fieldName: string, fieldValue: FieldValue, index: number) =>
    fieldName === "url" ? `${linkUrl}${fieldValue}&idx=${index}` : fieldValue;

  return hits.map((record, index) => {
    return Object.entries(record._source)
      .map(([fieldName, fieldValue]) => [
        fieldName,
        toObject(getFieldValue(fieldName, fieldValue, index), getHighlight(record, fieldName)),
      ])
      .reduce(addEachKeyValueToObject, {});
  });
}

/*
  Converts an Elasticsearch response to new application state

  When implementing an onSearch Handler in Search UI, the handler needs to convert
  search results into a new application state that Search UI understands.

  For instance, Elasticsearch returns "hits" for search results. This maps to
  the "results" property in application state, which requires a specific format. So this
  file iterates through "hits" and reformats them to "results" that Search UI
  understands.

  We do similar things for facets and totals.
*/
export default function buildState(response: IResponse, facetNames: string[], resultsPerPage?: number): IResponseState {
  const results: Result[] = !!response.hits ? buildResults(response.hits.hits, response.linkUrl) : [];
  const totalResults: number = !!response.hits ? buildTotalResults(response.hits) : 0;
  const totalPages: number = !!response ? buildTotalPages(resultsPerPage, totalResults) : 0;
  const facets: Record<string, FacetT[]> | undefined =
    !!response && buildStateFacets(response.aggregations, facetNames);
  return {
    results,
    totalPages,
    totalResults,
    ...(facets && { facets }),
  };
}
