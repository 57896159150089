import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";

import "./styles/index.css";
import "./styles/sidebar.css";
import "./styles/results.css";
import "./styles/onlinepanel.css";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import { SearchClient } from "./components/SearchClient";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const searchFieldSelector = (window as any).TeklaSearchFieldSelector
  ? (window as any).TeklaSearchFieldSelector
  : "#tekla-search-field";

const resultPageSelector = (window as any).TeklaSearchResultsSelector
  ? (window as any).TeklaSearchResultsSelector
  : "#tekla-search-results";

const onlinePanelSelector = (window as any).TeklaSearchOnlinePanelSelector
  ? (window as any).TeklaSearchOnlinePanelSelector
  : "#tekla-search-online-panel";
/* eslint-enable  @typescript-eslint/no-explicit-any */

const searchFieldElement = document.querySelector(searchFieldSelector);
const resultPageElement = document.querySelector(resultPageSelector);
const searchForOnlinePanelElement = document.querySelector(onlinePanelSelector);

function getLanguage(element): string {
  return element.getAttribute("data-language") || "en";
}

function getProduct(element): string {
  return element.getAttribute("data-product");
}

function getVersion(element): string {
  return element.getAttribute("data-version");
}

function getSearchTerm(element): string {
  return element.getAttribute("data-searchTerm") || "";
}

function getEnvironment(element): string {
  return element.getAttribute("data-env") || "prod";
}

function getService(element): string {
  return element.getAttribute("data-service") || "tua";
}

if (!!searchFieldElement) {
  const searchFieldRoot = createRoot(searchFieldElement!);
  searchFieldRoot.render(
    <React.StrictMode>
      <SearchClient
        root={searchFieldElement}
        service={getService(searchFieldElement)}
        env={getEnvironment(searchFieldElement)}
        display="searchField"
        product={getProduct(searchFieldElement)}
        version={getVersion(searchFieldElement)}
        searchTerm={getSearchTerm(searchFieldElement)}
        language={getLanguage(searchFieldElement)}
      />
    </React.StrictMode>,
  );
}

if (!!resultPageElement) {
  const resultPageRoot = createRoot(resultPageElement!);
  resultPageRoot.render(
    <React.StrictMode>
      <SearchClient
        root={resultPageElement}
        service={getService(resultPageElement)}
        env={getEnvironment(resultPageElement)}
        display="results"
        product={getProduct(resultPageElement)}
        version={getVersion(resultPageElement)}
        searchTerm={getSearchTerm(resultPageElement)}
        language={getLanguage(resultPageElement)}
      />
    </React.StrictMode>,
  );
}

if (!!searchForOnlinePanelElement) {
  const onlinePanelSearchRoot = createRoot(searchForOnlinePanelElement!);
  onlinePanelSearchRoot.render(
    <React.StrictMode>
      <SearchClient
        root={searchForOnlinePanelElement}
        service={getService(searchForOnlinePanelElement)}
        env={getEnvironment(searchForOnlinePanelElement)}
        display="onlinePanelView"
        product={getProduct(searchForOnlinePanelElement)}
        version={getVersion(searchForOnlinePanelElement)}
        searchTerm={getSearchTerm(searchForOnlinePanelElement)}
        language={getLanguage(searchForOnlinePanelElement)}
      />
    </React.StrictMode>,
  );
}
