/* eslint-disable prettier/prettier */
import axios, { AxiosResponse } from "axios";
import config from "../config";
import getDeviceIdentity from "./deviceIdentity";
import { IRequestBody, IResponse } from "../models/dataModel";
import { AuthStore } from "../stores/authStore";

/**
 * Sends a request for the Search API.
 * @param index index for the results
 * @param body request body
 */
export default async function runRequest(index: string, body: IRequestBody, authorizer: AuthStore): Promise<IResponse> {
  const idToken = await authorizer.getIdToken();

  try {
    return await makeApiRequest(index, idToken, body);
  } catch (error) {
    const newIdToken = await authorizer.updateIdToken();
    const isInternal = await authorizer.isInternalUser();

    if (!isInternal) {
      body = { ...body, query: { ...body.query, bool: { ...body.query.bool, must_not: { term: { access: "true" } } } } };
    }
    
    return await makeApiRequest(index, newIdToken, body);
  }
}

async function makeApiRequest(index: string, idToken: string, body: IRequestBody): Promise<IResponse> {
  const deviceIdentity = getDeviceIdentity();

  idToken = idToken || "none";

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const response: AxiosResponse<any> = await axios.post(`${config.endpoint}search?indices=${index}`, JSON.stringify(body), {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "IdToken " + idToken,
      "TSS-DV-ID" : deviceIdentity,
    },
  });

  if (response.status === 401) {
    throw new Error("Call to search API failed.");
  } else {
    if (!!response.data.queryId) {
      return { ...response.data, linkUrl: `${config.endpoint}url?queryId=${response.data.queryId}&target=` }
    } else {
      return response.data;
    }
  }
}
