import { Context, SortDirection } from "@elastic/react-search-ui";
import _ from "lodash";

import config from "../config";
import { IQuery, IRequestBody, IShouldQuery, ITermsAggregation } from "../models/dataModel";
import { AuthStore } from "../stores/authStore";
import { UIStateStore } from "../stores/uiStateStore";
import buildRequestFilter from "./buildRequestFilter";

const FIELDS_TO_SEARCH = [
  { name: "text_title", boost: 3 },
  { name: "sub_title", boost: 2 },
  { name: "keywords", boost: 1 },
  { name: "body", boost: 1 },
  { name: "teaser", boost: 1 },
  { name: "taxonomy_names", boost: 1 },
];

function buildFrom(current?: number, resultsPerPage?: number) {
  if (!current || !resultsPerPage) return;
  return (current - 1) * resultsPerPage;
}

function buildSort(sortDirection?: SortDirection, sortField?: string) {
  if (sortDirection && sortField) {
    return [{ [`${sortField}`]: sortDirection }];
  }
}

function buildOrMatch(searchTerm?: string): Record<string, IQuery>[] {
  let match: Record<string, IQuery>[] = [];

  if (searchTerm) {
    match = FIELDS_TO_SEARCH.map((field) => {
      return {
        match: {
          [field.name]: {
            query: searchTerm,
            boost: field.boost,
          },
        },
      };
    });
  }

  return match;
}

function buildAndMatch(searchTerm?: string): Record<string, IQuery>[] {
  let match: Record<string, IQuery>[] = [];

  if (searchTerm) {
    match = FIELDS_TO_SEARCH.map((field) => {
      return {
        match: {
          [field.name]: {
            query: searchTerm,
            operator: "and",
            boost: field.boost,
          },
        },
      };
    });
  }

  return match;
}

function buildMatchPhrase(searchTerm?: string): Record<string, IQuery>[] {
  let match: Record<string, IQuery>[] = [];

  if (searchTerm) {
    match = FIELDS_TO_SEARCH.map((field) => {
      return {
        match_phrase: {
          [field.name]: {
            query: searchTerm,
            boost: field.boost * 2,
          },
        },
      };
    });
  }

  return match;
}

function buildIfSearchTerm(searchTerm?: string): Record<string, IQuery>[] {
  return [...buildOrMatch(searchTerm), ...buildAndMatch(searchTerm), ...buildMatchPhrase(searchTerm)];
}

function buildShould(searchTerm?: string): IShouldQuery[] {
  if (searchTerm === "*" || searchTerm === "%2A") {
    searchTerm = undefined;
  }

  return !!searchTerm ? (buildIfSearchTerm(searchTerm) as IShouldQuery[]) : ([{ match_all: {} }] as IShouldQuery[]);
}

function buildIndicesBoost(index: string, selectedService: string) {
  const indices = index.split(",");

  if (indices.length > 1) {
    const selected = _.find(indices, (index: string) => {
      return index.includes(selectedService);
    });

    return [{ [selected!]: 5 }];
  }
}

/*

  Converts current application state to an Elasticsearch request.

  When implementing an onSearch Handler in Search UI, the handler needs to take the
  current state of the application and convert it to an API request.

  For instance, there is a "current" property in the application state that you receive
  in this handler. The "current" property represents the current page in pagination. This
  method converts our "current" property to Elasticsearch's "from" parameter.

  This "current" property is a "page" offset, while Elasticsearch's "from" parameter
  is a "item" offset. In other words, for a set of 100 results and a page size
  of 10, if our "current" value is "4", then the equivalent Elasticsearch "from" value
  would be "40". This method does that conversion.

  We then do similar things for searchTerm, filters, sort, etc.
*/
export default async function buildRequest(
  index: string,
  service: string,
  state: Context,
  authorizer: AuthStore,
  uiState: UIStateStore,
): Promise<IRequestBody> {
  const { current, filters, resultsPerPage, searchTerm, sortDirection, sortField } = state;

  const sort = buildSort(sortDirection, sortField);
  const should = buildShould(searchTerm);
  const indices_boost = buildIndicesBoost(index, service);
  const size = resultsPerPage;
  const from = buildFrom(current, resultsPerPage);
  const filter = buildRequestFilter(service, uiState, filters);
  const shouldExcludeUnpublished = [
    {
      bool: {
        must: [{ exists: { field: "status" } }, { term: { status: "true" } }],
        should: should,
        ...(filter && { filter }),
        minimum_should_match: 1,
      },
    },
    {
      bool: {
        must_not: { exists: { field: "status" } },
        should: should,
        ...(filter && { filter }),
        minimum_should_match: 1,
      },
    },
  ];

  const resolveQuery = async () => {
    if (await authorizer.isInternalUser()) {
      return {
        bool: {
          should: shouldExcludeUnpublished,
        },
      };
    } else {
      return {
        bool: {
          must_not: { term: { access: "true" } },
          should: shouldExcludeUnpublished,
        },
      };
    }
  };

  const resolveAggregations = (): Record<string, ITermsAggregation> => {
    let aggs: Record<string, ITermsAggregation> = {};
    const source = uiState.inGlobalSearchMode() ? config.global : config.services[service];

    source.filters.forEach((fieldName: string) => {
      aggs = _.extend(aggs, {
        [fieldName]: { terms: { field: fieldName, size: 100 } },
      });
    });

    !!uiState.getOpenCategory() &&
      config.services[uiState.getOpenCategory()!].filters.forEach((fieldName: string) => {
        aggs = _.extend(aggs, {
          [fieldName]: { terms: { field: fieldName, size: 100 } },
        });
      });

    return aggs;
  };

  function resolveSource(): string[] {
    let source: string[] = (uiState.inGlobalSearchMode() ? config.global : config.services[service]).resultFields;
    const openCategory: string = uiState.getOpenCategory()!;

    if (!!openCategory) {
      source = source.concat(config.services[openCategory].resultFields);
    }

    return _.uniq(source);
  }

  const body = {
    track_total_hits: true,
    // Static query Configuration
    // --------------------------
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-highlighting.html
    highlight: {
      fragment_size: 200,
      number_of_fragments: 1,
      fields: {
        title: {},
        teaser: {},
      },
    },
    //https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-source-filtering.html#search-request-source-filtering
    _source: resolveSource(),
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.11/search-aggregations.html
    aggs: resolveAggregations(),
    // Dynamic values based on current Search UI state
    // --------------------------
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/full-text-queries.html
    query: await resolveQuery(),
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.0/search-suggesters.html
    // suggest: {
    //   suggestion: {
    //     text: searchTerm,
    //     term: {
    //       field: "title",
    //     },
    //   },
    // },
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-search.html
    ...(indices_boost && { indices_boost }),
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-sort.html
    ...(sort && { sort }),
    // https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-request-from-size.html
    ...(size && { size }),
    ...(from && { from }),
  };

  return body;
}
