/* eslint-disable prettier/prettier */
import en from "./translations/en.json";
import cs from "./translations/cs-cz.json";
import de from "./translations/de-de.json";
import es from "./translations/es-es.json";
import et from "./translations/et-et.json";
import fi from "./translations/fi-fi.json";
import fr from "./translations/fr-fr.json";
import id from "./translations/id-id.json";
import it from "./translations/it-it.json";
import ja from "./translations/ja-jp.json";
import ko from "./translations/ko-kr.json";
import nl from "./translations/nl-nl.json";
import pl from "./translations/pl-pl.json";
import ptpt from "./translations/pt-pt.json";
import ptbr from "./translations/pt-br.json";
import ru from "./translations/ru-ru.json";
import sv from "./translations/sv-se.json";
import th from "./translations/th-th.json";
import zhhans from "./translations/zh-hans.json";

export const i18nConfig = {
  defaultLocale: "en",
  messages: {
    "cs": cs,
    "de": de,
    "en": en,
    "en-ae": en,
    "en-gb": en,
    "en-in": en,
    "en-sg": en,
    "en-us": en,
    "es": es,
    "la": es,
    "ee": et,
    "et": et,
    "fi": fi,
    "fr": fr,
    "id": id,
    "it": it,
    "ja": ja,
    "jp": ja,
    "ko": ko,
    "kr": ko,
    "nl": nl,
    "pl": pl,
    "br": ptbr,
    "pt-br": ptbr,
    "pt-pt": ptpt,
    "ru": ru,
    "sv": sv,
    "se": sv,
    "th": th,
    "ch": zhhans,
    "cn": zhhans,
    "zh-hans": zhhans
  },
};
