import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";
import { SearchProvider, Context, Filter, WithSearch, ResultT } from "@elastic/react-search-ui";
import _ from "lodash";

import "@elastic/react-search-ui-views/lib/styles/styles.css";

import { i18nConfig } from "../i18nConfig";

import config from "../config";
import buildRequest from "../utils/buildRequest";
import runRequest from "../utils/runRequest";
import applyDisjunctiveFaceting from "../utils/applyDisjunctiveFaceting";
import buildState from "../utils/buildState";
import { resolveLanguage, resolveSearchIndex } from "../utils/functions";

import { UIStateStore, UIStateStoreContext } from "../stores/uiStateStore";
import { AuthStore } from "../stores/authStore";
import { FilterOptionEnum, FilterTypeEnum, IResponse } from "../models/dataModel";
import { ResultPage } from "./ResultPage";
import { SearchField } from "./SearchField";
import { OnlinePanelView } from "./OnlinePanelView";

/**
 * Main component for the Search Client. Injects the SearchProvider, resolves the initial state,
 * and based on given display value renders the app either in search field view or the results view.
 * @param root the root element
 * @param service the service where the search is embedded in
 * @param env selected environment
 * @param language the selected language
 * @param display sets the app view state (results / searchField / onlinePanelView)
 * @param product selected product (optional)
 * @param version selected version (optional)
 * @param searchTerm the initial search term when app is loaded (optional)
 */
export const SearchClient = ({
  root,
  service,
  display,
  language,
  product,
  version,
  searchTerm,
  env,
}: {
  root: Element;
  service: string;
  display: string;
  language: string;
  product?: string;
  version?: string;
  searchTerm?: string;
  env: string;
}): JSX.Element => {
  const uiStore: UIStateStore = new UIStateStore();
  const authorizer: AuthStore = new AuthStore();
  const showResults: boolean = display === "results";

  service = !!_.find(_.keys(config.services), (key: string) => key === service) ? service : "tua";

  const notVersionSpecific =
    i18nConfig.messages[i18nConfig.defaultLocale]["facets.tua.software_version.notVersionSpecific"];

  uiStore.setSelectedService(service);
  uiStore.setOnlinePanelMode(display === "onlinePanelView");
  uiStore.setLanguage(resolveLanguage(language, service) || "en");

  /**
   * If service === "tua", sets initial filters based on values in the data-product & data-version params.
   * If data-version is given, adds the filter 'Not version-specific' as well.
   */
  function resolveInitialFilters(): Filter[] {
    const filters: Filter[] = [];

    if (service === "tua") {
      product = root.getAttribute("data-product") || undefined;
      version = root.getAttribute("data-version") || undefined;

      if (!!product) {
        filters.push({
          field: FilterOptionEnum.PRODUCT,
          values: [product],
          type: FilterTypeEnum.ALL,
        });

        uiStore.setFacetState(FilterOptionEnum.PRODUCT, true);
      }

      if (!!version) {
        filters.push({
          field: FilterOptionEnum.VERSION,
          values: [version, notVersionSpecific],
          type: FilterTypeEnum.ANY,
        });

        uiStore.setFacetState(FilterOptionEnum.VERSION, true);
      }

      if (!!product || !!version) {
        filters.push({
          field: FilterOptionEnum.CATEGORY,
          values: [config.services["tua"].categoryName],
          type: FilterTypeEnum.ALL,
        });

        uiStore.setFacetState(FilterOptionEnum.CATEGORY, true);
        uiStore.setOpenCategory(config.services["tua"].categoryName);
      }
    }

    return filters;
  }

  const searchConfig = {
    alwaysSearchOnInitialLoad: false,
    debug: false,
    hasA11yNotifications: true,
    initialState: showResults
      ? {
        filters: resolveInitialFilters(),
        searchTerm: searchTerm,
        resultsPerPage: 10,
      }
      : {
        resultsPerPage: 10,
      },
    trackUrlState: showResults,
    onResultClick: () => { },
    onAutocompleteResultClick: () => { },
    onAutocomplete: async (state: Context) => {
      const index: string = resolveSearchIndex(env, language, service, uiStore.inGlobalSearchMode());
      const requestBody = await buildRequest(
        index,
        service,
        { searchTerm: state.searchTerm, filters: uiStore.getAutocompleteFilters() || resolveInitialFilters() },
        authorizer,
        uiStore,
      );
      const json = await runRequest(index, requestBody, authorizer);

      let filters = (uiStore.inGlobalSearchMode() ? config.global : config.services[service]).filters;
      const openCategory: string = uiStore.getOpenCategory()!;

      if (!!openCategory) {
        filters = filters.concat(config.services[openCategory].filters);
      }

      const responseState = buildState(json, filters);
      return {
        autocompletedResults: _.map(responseState.results, (result: ResultT) => {
          return { ...result, _meta: { id: result.id } };
        }),
      };
    },
    onSearch: async (state: Context) => {
      const { resultsPerPage } = state;
      const index: string = resolveSearchIndex(env, language, service, uiStore.inGlobalSearchMode());
      const requestBody = uiStore.inOnlinePanelMode()
        ? await buildRequest(index, service, { ...state, filters: resolveInitialFilters() }, authorizer, uiStore)
        : await buildRequest(index, service, state, authorizer, uiStore);

      // Note that this could be optimized by running all of these requests
      // at the same time. Kept simple here for clarity.
      const responseJson: IResponse = await runRequest(index, requestBody, authorizer);

      let filters = (uiStore.inGlobalSearchMode() ? config.global : config.services[service]).filters;
      const openCategory: string = uiStore.getOpenCategory()!;

      if (!!openCategory) {
        filters = filters.concat(config.services[openCategory].filters);
      }

      const responseJsonWithDisjunctiveFacetCounts: IResponse = await applyDisjunctiveFaceting(
        service,
        index,
        responseJson,
        state,
        filters,
        authorizer,
        uiStore,
      );

      return buildState(responseJsonWithDisjunctiveFacetCounts, filters, resultsPerPage);
    },
  };

  return (
    <IntlProvider
      locale={language}
      defaultLocale={i18nConfig.defaultLocale}
      messages={i18nConfig.messages[language] || i18nConfig.messages[i18nConfig.defaultLocale]}
    >
      <UIStateStoreContext.Provider value={uiStore}>
        <SearchProvider config={searchConfig}>
          <WithSearch mapContextToProps={({ addFilter, removeFilter }) => ({ addFilter, removeFilter })}>
            {({ addFilter, removeFilter }) => {
              const resultStateObserver = new MutationObserver(() => {
                const initFilters = resolveInitialFilters();
                initFilters.forEach((filter: Filter) => {
                  removeFilter(filter.field);
                  addFilter(filter.field, filter.values, filter.type);
                });
              });

              /** Observes changes in the product/version fields */
              resultStateObserver.observe(root, {
                attributes: true,
                attributeFilter: ["data-version", "data-product"],
              });

              return (
                <Fragment>
                  {display === "results" && <ResultPage />}
                  {display === "searchField" && <SearchField filters={resolveInitialFilters()} />}
                  {display === "onlinePanelView" && <OnlinePanelView />}
                </Fragment>
              );
            }}
          </WithSearch>
        </SearchProvider>
      </UIStateStoreContext.Provider>
    </IntlProvider>
  );
};
