import React, { Fragment, useContext } from "react";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { FacetValue, func, Result, WithSearch } from "@elastic/react-search-ui";

import { FilterOptionEnum } from "../../models/dataModel";

import { UIStateStore, UIStateStoreContext } from "../../stores/uiStateStore";
import { FacetTitle } from "./FacetTitle";
import { ShowMoreOptions } from "./ShowMoreOptions";
import { SubFacets } from "../sidebar/Facets";

const RadioButtonOption = observer(
  ({ option, facetName, onChange }: { option: FacetValue; facetName: string; onChange: func }) => {
    const uiStore: UIStateStore = useContext(UIStateStoreContext);
    const checked: boolean | undefined = option.selected;
    const categoryKey: string | undefined = uiStore.getCategoryKeyBasedOnName(option.value as string);

    const displaySubFacets: boolean =
      facetName === FilterOptionEnum.CATEGORY &&
      !!categoryKey &&
      uiStore.categoryHasSubFacetsAndIsSelected(categoryKey);

    function handleChange() {
      onChange(option.value);
    }

    return (
      <Fragment>
        <li className="option">
          <label className="radio-button">
            <span className="radio-button-input" data-testid={`selectFilter-${option.value}`}>
              <input
                type="radio"
                name={`radio-${facetName}`}
                className="radio-button"
                value={option.value as string}
                checked={checked}
                onChange={handleChange}
                data-testid={`radioButtonInput-${option.value}`}
              />
              <span className="radio-button-control" />
            </span>
            <span className="radio-button-label">{option.value as string}</span>
            <WithSearch mapContextToProps={({ results }) => ({ results })}>
              {({ results }: { results: Result[] }) => (
                <Fragment>
                  {!_.isEmpty(results) && <span className="facet-result-count small-text">{option.count}</span>}
                </Fragment>
              )}
            </WithSearch>
          </label>
        </li>
        {displaySubFacets && <SubFacets openCategory={categoryKey!} />}
      </Fragment>
    );
  },
);

const FacetContent = observer(
  ({
    labelKey,
    options,
    showMore,
    onChange,
    onMoreClick,
  }: {
    labelKey: string;
    options: FacetValue[];
    showMore: boolean;
    onChange: func;
    onMoreClick: func;
  }): JSX.Element => {
    const uiStore: UIStateStore = useContext(UIStateStoreContext);
    const facetValue: string | undefined = _.find(uiStore.getAvailableFacets(), (value) => value.label === labelKey)
      ?.field;
    const isOpen: boolean = !!facetValue && uiStore.isFacetOpen(facetValue);

    return (
      <div className="sui-facet" data-testid={`radioButtonFacet-${facetValue}`}>
        <FacetTitle titleKey={labelKey} />
        {isOpen && (
          <Fragment>
            <ul className="options">
              {_.map(options, (option: FacetValue, i: number) => {
                return <RadioButtonOption option={option} facetName={facetValue || ""} onChange={onChange} key={i} />;
              })}
            </ul>
            {showMore && <ShowMoreOptions onShowMore={onMoreClick} />}
          </Fragment>
        )}
      </div>
    );
  },
);
/**
 * Renders the custom radio button facet.
 * @param label name of the facet
 * @param options options for the facet
 * @param onChange handles selecting an option
 */
export const RadioButtonFacet = ({
  label,
  options,
  showMore,
  onChange,
  onMoreClick,
}: {
  label: string;
  options: FacetValue[];
  showMore;
  onChange: func;
  onMoreClick: func;
}): JSX.Element => {
  return (
    <FacetContent
      labelKey={label}
      options={options}
      showMore={showMore}
      onChange={onChange}
      onMoreClick={onMoreClick}
    />
  );
};
