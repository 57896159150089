import React, { Fragment, useState } from "react";

import { ErrorBoundary, Filter, func, SearchBox } from "@elastic/react-search-ui";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import { injectIntl, IntlShape, useIntl } from "react-intl";
import { resolveQueryString } from "../utils/functions";

/**
 * The custom input component that includes our search icon.
 * @param getAutocomplete used to determine where the autocomplete dropdown will be shown.
 * @param getInputProps returns the input props
 * @param getButtonProps returns the button props
 */
export const SearchFieldInput = ({
  getAutocomplete,
  getInputProps,
  getButtonProps,
}: {
  getAutocomplete: func;
  getInputProps: func;
  getButtonProps: func;
}): JSX.Element => {
  const [disabled, setDisabled] = useState(false);

  function customOnInput(e) {
    setDisabled(e.currentTarget.value === "");
    return e.currentTarget.value;
  }

  return (
    <Fragment>
      <div className="sui-search-box__wrapper">
        <input
          {...getInputProps()}
          placeholder={useIntl().formatMessage({ id: "searchPlaceholder" })}
          data-testid="customSearchField"
          onInput={customOnInput}
        />
        {getAutocomplete()}
      </div>
      <button
        {...getButtonProps({
          disabled: disabled,
          className: "search-button",
        })}
        data-testid="searchButton"
      >
        <SearchIcon />
      </button>
    </Fragment>
  );
};

/**
 * Search field component, used by itself as an exported component.
 * On search event, redirects the user to a /search page with selected filters
 */
export const SearchField = injectIntl(({ intl, filters }: { intl: IntlShape; filters: Filter[] }): JSX.Element => {
  function navigate(url: string): void {
    const lang = `/${intl.locale}`;
    const urlWithLang: string = intl.locale === "en" ? url : lang + url;

    location.assign(urlWithLang);
  }

  return (
    <div className="trimble-search" id="searchField" data-testid="search-field">
      <ErrorBoundary>
        <Fragment>
          <div className="search-field">
            <SearchBox
              onSubmit={(searchTerm) => {
                if (!!searchTerm) navigate("/search?q=" + encodeURIComponent(searchTerm) + resolveQueryString(filters));
              }}
              onSelectAutocomplete={(selection, {}, defaultOnSelectAutocomplete) => {
                if (selection.suggestion) {
                  navigate("/search?q=" + encodeURIComponent(selection.suggestion));
                } else {
                  defaultOnSelectAutocomplete(selection);
                }
              }}
              autocompleteMinimumCharacters={3}
              autocompleteResults={{
                linkTarget: "_blank",
                titleField: "title",
                urlField: "url",
                shouldTrackClickThrough: true,
                clickThroughTags: ["autocomplete"],
              }}
              autocompleteSuggestions={true}
              searchAsYouType={false}
              shouldClearFilters={false}
              inputView={SearchFieldInput}
            />
          </div>
        </Fragment>
      </ErrorBoundary>
    </div>
  );
});
