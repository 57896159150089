import { FacetProps, FacetValue, Filter } from "@elastic/react-search-ui";
import _ from "lodash";
import { action, configure, makeAutoObservable, observable, toJS } from "mobx";
import { createContext } from "react";
import config from "../config";
import { FilterOptionEnum, ServiceEnum } from "../models/dataModel";
import { hasSubcategories } from "../utils/functions";
import { i18nConfig } from "../i18nConfig";

configure({ isolateGlobalState: true, useProxies: "never", enforceActions: "observed" });

/**
 * Store for handling UI state
 */
export class UIStateStore {
  /**
   * Stores filters, used for making the autocomplete query.
   */
  @observable private autocompleteFilters?: Filter[];

  /**
   * Stores the facet states - if true, facet is open
   */
  @observable private facetStates = observable.map({
    product: false,
    software_version: false,
    content_area: false,
    content_type: true,
    type: false,
    environment: false,
  });

  /**
   * Stores the open category, used for displaying index specific facets.
   */
  @observable private openCategory?: string;

  /**
   * Stores the category options.
   */
  @observable private categoryOptions: FacetValue[] = [];

  /**
   * Stores the service in which the Search Client is embedded.
   */
  @observable private service?: string;

  /**
   * Stores the language key.
   */
  @observable private languageKey?: string;

  /**
   * Stores open category specific filters when category is changed
   */
  @observable private openCategorySpecificFilters: Record<string, Filter[]> = {};

  /**
   * True if search is rendered in online panel.
   */
  @observable private onlinePanelMode = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Returns a list of autocomplete filters.
   * @returns filters
   */
  public getAutocompleteFilters(): Filter[] | undefined {
    return this.autocompleteFilters;
  }

  /**
   * Sets the filters used in the autocomplete query
   * @param filters filters to use on onAutocomplete
   */
  @action
  public setAutocompleteFilters(filters: Filter[]): void {
    this.autocompleteFilters = filters;
  }

  /**
   * Returns stored category options
   * @returns category options
   */
  public getSortedCategoryOptions(): FacetValue[] {
    return _.sortBy(this.categoryOptions, (option: FacetValue) => option.value);
  }

  /**
   * Sets the category options
   * If stored categoryOptions is empty, sets the options from props
   * If stored categoryOptions is not empty, combines the existing array with the new one,
   * keeping updated values from the props.
   * @param categoryOptions category options
   */
  @action
  public setCategoryOptions(categoryOptions: FacetValue[], categoryChanged?: boolean): void {
    if (_.isEmpty(this.categoryOptions)) {
      this.categoryOptions = categoryOptions;
    } else {
      // if options do not include info about facet counts or facet selection, return without assigning new options.
      if (!_.find(categoryOptions, (o: FacetValue) => !!o.count || !!o.selected)) return;

      const selected = _.find(categoryOptions, (o: FacetValue) => o.selected) as FacetValue;
      if (!!selected) {
        // If selected category doesn't change & given category has no subcategories, don't assign new options.
        if (!categoryChanged && !hasSubcategories(selected)) return;

        // if category is selected, update count - else keep old count
        categoryOptions = _.map(categoryOptions, (option: FacetValue) => {
          const oldValue = _.find(this.categoryOptions, (o: FacetValue) => o.value === option.value);

          return {
            value: option.value,
            count: option.selected ? option.count : oldValue?.count,
            selected: option.selected,
          };
        });
      }

      this.categoryOptions = _.chain(categoryOptions).concat(toJS(this.categoryOptions)).uniqBy("value").value();
    }
  }

  /**
   * Returns the language key
   */
  @action
  public setLanguage(language: string): void {
    this.languageKey = language;
  }

  /**
   * Returns the open category (in index form)
   * @returns open category key ("tua" / "com")
   */
  public getOpenCategory(): string | undefined {
    return _.find(Object.keys(config.services), (serviceKey: string) => {
      const translatedCategoryName =
        i18nConfig.messages[this.languageKey || "en"][`facets.category.${serviceKey}`] ||
        i18nConfig.messages[i18nConfig.defaultLocale][`facets.category.${serviceKey}`];

      return translatedCategoryName === this.openCategory;
    });
  }

  /**
   * Returns the category key (in index form) based on given category name
   * @returns category key ("tua" / "com")
   */
  public getCategoryKeyBasedOnName(categoryName: string): string | undefined {
    return _.find(Object.keys(config.services), (serviceKey: string) => {
      const translatedCategoryName =
        i18nConfig.messages[this.languageKey || "en"][`facets.category.${serviceKey}`] ||
        i18nConfig.messages[i18nConfig.defaultLocale][`facets.category.${serviceKey}`];

      return translatedCategoryName === categoryName;
    });
  }


  /**
   * Sets the open category name
   * @param name open category name ("User Assistance", "Product Information")
   */
  @action
  public setOpenCategory(name: string | undefined): void {
    this.openCategory = name;

    if (!!name && !_.isEmpty(this.categoryOptions)) {
      // add 'selected' to open category, false for others
      const newOptions: FacetValue[] = _.map(this.categoryOptions, (option: FacetValue) => {
        if (option.value === name) return { ...option, selected: true };
        else return { ...option, selected: false };
      });

      this.setCategoryOptions(newOptions, true);
    }
  }

  /**
   * Returns true if category has subfacets & is selected
   * NOTE: should update this if more categories with subfacets are added!
   * @param selected selected category key
   * @returns
   */
  public categoryHasSubFacetsAndIsSelected(selected: string): boolean {
    return (
      this.getOpenCategory() === selected &&
      _.includes([ServiceEnum.DEVELOPER_CENTER, ServiceEnum.TEKLA_USER_ASSISTANCE], selected)
    );
  }

  /**
   * Returns possible open filters for the given category
   * @returns list of open filters for the category
   */
  public getOpenCategoryFilters(categoryName: string): Filter[] {
    return !!this.openCategorySpecificFilters && !!this.openCategorySpecificFilters[categoryName]
      ? this.openCategorySpecificFilters[categoryName]
      : [];
  }

  /**
   * Sets open category filters for the open category
   */
  @action
  public setOpenCategoryFilters(filters: Filter[], clearAll?: boolean): void {
    if (clearAll) {
      this.openCategorySpecificFilters = {};
    } else {
      filters = _.filter(filters, (filter: Filter) => filter.field !== FilterOptionEnum.CATEGORY);

      if (this.openCategory) {
        this.openCategorySpecificFilters = { ...this.openCategorySpecificFilters, [this.openCategory]: filters };
      }
    }
  }

  /**
   * Returns a list of available facets. If in global mode & a category is open,
   * this includes main facets and subfacets.
   * @returns list of available facets
   */
  public getAvailableFacets(): FacetProps[] | undefined {
    let facets = (this.inGlobalSearchMode() ? config.global : config.services[this.service!]).facetList;

    if (!!facets && !!this.getOpenCategory()) {
      facets = facets.concat(config.services[this.getOpenCategory()!].facetList!);
    }

    return facets;
  }

  /**
   * Returns the selected service in which the Search Client is embedded.
   * @returns the selected service
   */
  public getService(): string {
    return this.service || "";
  }

  /**
   * Checks if the given facet is currently open.
   * @param facetKey facet key
   */
  public isFacetOpen(facetKey: string): boolean {
    return !!this.facetStates.get(facetKey);
  }

  /**
   * Checks if the client is in global search mode
   * NOTE: Should be updated as more services are added to the global search scope.
   * @returns true/false
   */
  public inGlobalSearchMode(): boolean {
    const services = _.keys(config.services);
    return !!this.service && services.includes(this.service);
  }

  /**
   * Checks if the client is in online panel mode
   * @returns true/false
   */
  public inOnlinePanelMode(): boolean {
    return this.onlinePanelMode;
  }

  /**
   * Modifies given facet state to the given value.
   * @param facetKey facet key
   * @param state boolean that tells if facet is open or closed
   */
  @action
  public setFacetState(facetKey: string, state: boolean): void {
    this.facetStates.set(facetKey, state);
  }

  /**
   * Sets the value for isInOnlinePanelMode
   * @param inOnlinePanel true/false
   */
  @action
  public setOnlinePanelMode(inOnlinePanel: boolean): void {
    this.onlinePanelMode = inOnlinePanel;
  }

  /**
   * Sets the selected service
   * @param service the selected service ("tua", "com"...)
   */
  @action
  public setSelectedService(service: string): void {
    this.service = service;
  }
}

export const UIStateStoreContext = createContext<UIStateStore>(new UIStateStore());
