import React, { useContext, useEffect, useState } from "react";
import { FacetValue, Filter, FilterValue, func, WithSearch } from "@elastic/react-search-ui";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import { IntlShape, useIntl } from "react-intl";

import config from "../../config";
import { i18nConfig } from "../../i18nConfig";

import { UIStateStore, UIStateStoreContext } from "../../stores/uiStateStore";
import { FilterOptionEnum } from "../../models/dataModel";
import { RadioButtonFacet } from "./RadioButtonFacet";

const CategoryFacet = observer(
  ({
    labelKey,
    options,
    showMore,
    onChange,
    onMoreClick,
    filters,
    removeFilter,
    addFilter,
    clearFilters,
    hasResolvedCategory,
    setHasResolvedCategory,
  }: {
    labelKey: string;
    options: FacetValue[];
    showMore: boolean;
    onChange: func;
    onMoreClick: func;
    filters: Filter[];
    removeFilter: func;
    addFilter: func;
    clearFilters: func;
    hasResolvedCategory: boolean;
    setHasResolvedCategory: func;
  }): JSX.Element => {
    const uiStore: UIStateStore = useContext(UIStateStoreContext);
    const selected: FacetValue | undefined = options.find((option: FacetValue) => {
      return option.selected;
    });

    useEffect(() => {
      if (!hasResolvedCategory && !!selected && !uiStore.getOpenCategory()) {
        resolveOpenCategory(selected, removeFilter, addFilter);
        setHasResolvedCategory(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, uiStore.getOpenCategory()]);

    function resolveOpenCategory(selected: FacetValue, removeFilter: func, addFilter: func) {
      uiStore.setOpenCategory(selected.value as string);
      removeFilter(FilterOptionEnum.CATEGORY);
      addFilter(FilterOptionEnum.CATEGORY, selected.value);
    }

    function onSelectCategory(value: string, clearFilters: func, addFilter: func) {
      clearFilters();

      if (uiStore.getOpenCategoryFilters(value)) {
        _.forEach(uiStore.getOpenCategoryFilters(value), (filter: Filter) =>
          _.forEach(filter.values, (value: FilterValue) => {
            addFilter(filter.field, value, filter.type);
          }),
        );
      }

      uiStore.setOpenCategory(value);
      onChange(value);
    }

    function handleChange(value: string) {
      uiStore.setOpenCategoryFilters(filters);
      onSelectCategory(value, clearFilters, addFilter);
    }

    return (
      <RadioButtonFacet
        label={labelKey}
        options={uiStore.getSortedCategoryOptions()}
        showMore={showMore}
        onChange={handleChange}
        onMoreClick={onMoreClick}
      />
    );
  },
);

/**
 * Renders the facet with the WithSearch render props
 * Gets initial category options from global search service list
 */
const FacetWrapper = observer(
  ({
    labelKey,
    options,
    showMore,
    onChange,
    onMoreClick,
  }: {
    labelKey: string;
    options: FacetValue[];
    showMore: boolean;
    onChange: func;
    onMoreClick: func;
  }): JSX.Element => {
    const uiStore: UIStateStore = useContext(UIStateStoreContext);
    const intl: IntlShape = useIntl();
    const [hasResolvedCategory, setHasResolvedCategory] = useState(false);

    // get details for category facets from options (result count, isSelected)
    useEffect(() => {
      if (!_.isEmpty(options)) {
        uiStore.setCategoryOptions(options);
      }
    }, [options, uiStore]);

    // on first load, set category facets based on services
    useEffect(() => {
      const serviceOptions: FacetValue[] = _.map(Object.keys(config.services), (service: string) => {
        return {
          value: intl.formatMessage({
            id: `facets.category.${service}`,
            defaultMessage: i18nConfig.messages["en"][`facets.category.${service}`],
          }),
        };
      });

      uiStore.setCategoryOptions(serviceOptions);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <WithSearch
        mapContextToProps={({ clearFilters, removeFilter, addFilter, filters }) => ({
          clearFilters,
          removeFilter,
          addFilter,
          filters,
        })}
      >
        {(props) => (
          <CategoryFacet
            labelKey={labelKey}
            options={options}
            showMore={showMore}
            onChange={onChange}
            onMoreClick={onMoreClick}
            filters={props.filters}
            clearFilters={props.clearFilters}
            removeFilter={props.removeFilter}
            addFilter={props.addFilter}
            hasResolvedCategory={hasResolvedCategory}
            setHasResolvedCategory={setHasResolvedCategory}
          />
        )}
      </WithSearch>
    );
  },
);

/**
 * Renders the "Select category" facet.
 * @param label name of the facet
 * @param options options for the facet
 * @param onChange handles selecting an option
 */
export const SelectCategoryFacet = ({
  label,
  options,
  showMore,
  onChange,
  onMoreClick,
}: {
  label: string;
  options: FacetValue[];
  showMore: boolean;
  onChange: func;
  onMoreClick: func;
}): JSX.Element => {
  return (
    <FacetWrapper
      labelKey={label}
      options={options}
      showMore={showMore}
      onChange={onChange}
      onMoreClick={onMoreClick}
    />
  );
};
