import _ from "lodash";

import { FacetT, FacetValue, Filter, FilterValue } from "@elastic/react-search-ui";
import { FilterOptionEnum } from "../models/dataModel";
import config from "../config";

/**
 * Checks if a product filter has been set
 * @param filters list of filters to check
 */
export function hasProductBeenSelected(filters: Filter[]): boolean {
  return !!filters.find((filter: Filter) => filter.field === FilterOptionEnum.PRODUCT);
}

/**
 * Checks if a version filter has been set
 * @param filters list of filters to check
 */
export function hasVersionBeenSelected(filters: Filter[]): boolean {
  return !!filters.find((filter: Filter) => filter.field === FilterOptionEnum.VERSION);
}

/**
 * Checks if a given category facet has subcategories
 * @param category FacetValue
 */
export function hasSubcategories(category: FacetValue): boolean {
  const categoryConfig = !!category && _.find(config.services, (service) => service.categoryName === category.value);
  return !!categoryConfig && !_.isUndefined(categoryConfig.facetList) && !_.isEmpty(categoryConfig.facetList);
}

/**
 * Checks if a version facet option exists
 * @param facets list of facets to check
 * @param version name of the version
 */
export function checkIfVersionExists(facets: Record<string, FacetT[]>, version: string): boolean {
  const versionFacet = !!facets && !!facets.software_version && facets.software_version[0];
  return !!versionFacet && !!versionFacet.data.find((option: FacetValue) => option.value === version);
}

/**
 * Returns a sorted list of facet options with selected options at the top.
 * Adds selected options to the top even if they don't appear among the most popular options.
 * @param facetValue selected facet
 * @param options the top X options for facet, updated if user clicks 'show more'
 * @param filters list of all selected filters
 * @param allFacets list of all facets
 * @returns list of options, sorted by selected
 */
export function getSortedFacetOptions(
  facetValue: string,
  options: FacetValue[],
  filters: Filter[],
  allFacets: Record<string, FacetT[]>,
): FacetValue[] {
  const facet: FacetT | undefined = !!allFacets[facetValue] && allFacets[facetValue][0];

  if (!facet) {
    return _.sortBy(options, (option: FacetValue) => !option.selected);
  } else {
    const optionsWithSelected: FacetValue[] = options;
    const allFacetValues: FacetValue[] = facet.data;

    if (allFacetValues.length === options.length) {
      return _.sortBy(options, (option: FacetValue) => !option.selected);
    }

    const filtersForFacet: Filter[] | undefined = _.filter(filters, (filter: Filter) => filter.field === facetValue);

    !!filtersForFacet &&
      !!filtersForFacet[0] &&
      _.forEach(filtersForFacet[0].values, (filterValue: FilterValue) => {
        const optionVisible = !!_.find(options, (option: FacetValue) => {
          return option.value === filterValue;
        });

        if (!optionVisible) {
          const optionForFilter = _.find(allFacetValues, (option: FacetValue) => {
            return option.value === filterValue;
          });

          if (optionForFilter) {
            optionsWithSelected.push({
              ...optionForFilter,
              selected: true,
            });
          }
        }
      });

    return _.sortBy(optionsWithSelected, (option: FacetValue) => !option.selected);
  }
}

/**
 * Resolves the search index based on environemnt, language and the service.
 * @param env the environment
 * @param language language given to the search client
 * @param service the service in which the client is rendered
 * @param searchAllIndices should search all indices?
 * @returns the search index
 */
export function resolveSearchIndex(env: string, language: string, service: string, searchAllIndices: boolean): string {
  const validEnv = resolveEnvironment(env || "prod");

  if (searchAllIndices) {
    return _(_.keys(config.services))
      .filter((serviceKey: string) => {
        return !!resolveLanguage(language, serviceKey);
      })
      .map((serviceKey: string) => {
        return `${serviceKey}_${validEnv}-${resolveLanguage(language, serviceKey)}`;
      })
      .join(",");
  }

  return !!resolveLanguage(language, service) ? `${service}_${validEnv}-${resolveLanguage(language, service)}` : "";
}

/**
 * Resolves the environment in short format based on given env string.
 * @param env environment, given to search client through anchor div
 * @returns the env string in short format
 */
function resolveEnvironment(env: string): string {
  if (env === "local") return "local";
  else if (env === "dev" || env === "development") return "dev";
  else if (env === "stg" || env === "staging") return "stg";
  else if (env === "prod" || env === "production") return "prod";
  else return "prod";
}

/**
 * Resolves the service specific language used for indexing search content.
 * @param lang language key
 * @param service service to resolve language for (tua/com)
 * @returns language string or undefined if content for given language does not exists in the given service
 */
export function resolveLanguage(lang: string, service: string): string | undefined {
  const mappedLanguages = {
    cs: { com: undefined, tua: "cs", down: undefined, devctr: undefined },
    de: { com: "de", tua: "de", down: "de", devctr: undefined },
    en: { com: "en", tua: "en", down: "en", devctr: "en" },
    ae: { com: "en-ae", tua: "en", down: "en", devctr: "en" },
    uk: { com: "en-gb", tua: "en", down: "en", devctr: "en" },
    in: { com: "en-in", tua: "en", down: "en", devctr: "en" },
    sg: { com: "en-sg", tua: "en", down: "en", devctr: "en" },
    us: { com: "en-us", tua: "en", down: "en", devctr: "en" },
    es: { com: "es", tua: "es", down: "es", devctr: undefined },
    la: { com: "es", tua: "es", down: "es", devctr: undefined },
    ee: { com: "et", tua: undefined, down: undefined, devctr: undefined },
    et: { com: "et", tua: undefined, down: undefined, devctr: undefined },
    fi: { com: "fi", tua: "fi", down: undefined, devctr: undefined },
    fr: { com: "fr", tua: "fr", down: "fr", devctr: undefined },
    id: { com: "in", tua: undefined, down: undefined, devctr: undefined },
    it: { com: undefined, tua: "it", down: "it", devctr: undefined },
    ja: { com: "jp", tua: "jp", down: "jp", devctr: undefined },
    jp: { com: "jp", tua: "jp", down: "jp", devctr: undefined },
    ko: { com: "ko", tua: "ko", down: "ko", devctr: undefined },
    kr: { com: "ko", tua: "ko", down: "ko", devctr: undefined },
    nl: { com: undefined, tua: "nl", down: "nl", devctr: undefined },
    pl: { com: undefined, tua: "pl", down: "pl", devctr: undefined },
    br: { com: "pt-br", tua: "pt-pt", down: undefined, devctr: undefined },
    "pt-br": { com: "pt-br", tua: undefined, down: undefined, devctr: undefined },
    "pt-pt": { com: undefined, tua: "pt-pt", down: undefined, devctr: undefined },
    ru: { com: "ru", tua: "ru", down: "ru", devctr: undefined },
    se: { com: "sv", tua: "sv", down: undefined, devctr: undefined },
    sv: { com: "sv", tua: "sv", down: undefined, devctr: undefined },
    th: { com: "th", tua: undefined, down: undefined, devctr: undefined },
    ch: { com: "zh-hans", tua: "zh-hans", down: "zh-hans", devctr: undefined },
    cn: { com: "zh-hans", tua: "zh-hans", down: "zh-hans", devctr: undefined },
    "zh-hans": { com: "zh-hans", tua: "zh-hans", down: "zh-hans", devctr: undefined },
  };

  return !!mappedLanguages[lang] && mappedLanguages[lang][service];
}

/**
 * Resolves a query string from given list of filters
 * @param filters list of existing filters
 * @returns search query for the url
 */
export function resolveQueryString(filters: Filter[]): string {
  let queryStr = "";

  _.each(filters, (filter: Filter, i: number) => {
    queryStr =
      queryStr +
      `&filters[${i}][field]=${filter.field}` +
      _.map(filter.values, (filterValue: FilterValue, j: number) => `&filters[${i}][values][${j}]=${filterValue}`).join(
        "",
      ) +
      `&filters[${i}][type]=${filter.type}`;
  });

  return queryStr;
}
