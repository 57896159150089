import React, { Fragment, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

import { WithSearch, PagingInfo, Result, Context } from "@elastic/react-search-ui";

import { IResult } from "../../models/dataModel";

import { ResultBox } from "./../results/ResultBox";
import { SelectedFilters } from "./../results/SelectedFilters";
import { CustomPaging } from "./../results/CustomPaging";
import { Spinner } from "../common/Spinner";
import { UIStateStoreContext } from "../../stores/uiStateStore";
import { observer } from "mobx-react-lite";

const CustomPagingInfo = ({ start, end, totalResults }: { start: string; end: string; totalResults: number }) => (
  <div className="paging-info">
    <p data-testid="showingResultsMessage">
      <FormattedMessage id="header.showingResults" values={{ start: start, end: end, totalResults: totalResults }} />
    </p>
  </div>
);

const ResultsList = (): JSX.Element => {
  function renderNoResultsMessage(resultSearchTerm: string): JSX.Element {
    return (
      <h3 className="no-results-message centered" data-testid="noResultsMessage">
        <FormattedMessage id="noResults" values={{ searchTerm: resultSearchTerm }} />
      </h3>
    );
  }

  return (
    <WithSearch
      mapContextToProps={({ results, wasSearched, resultSearchTerm }) => ({ results, wasSearched, resultSearchTerm })}
    >
      {({ resultSearchTerm, wasSearched, results }) => (
        <Fragment>
          {wasSearched && (
            <div className="result-list" data-testid="resultList">
              {!!results && !_.isEmpty(results)
                ? results.map((result: IResult) => <ResultBox key={result.id.raw as string} result={result} />)
                : renderNoResultsMessage(resultSearchTerm)}
            </div>
          )}
        </Fragment>
      )}
    </WithSearch>
  );
};

const ResultsHeader = observer((): JSX.Element => {
  const store = useContext(UIStateStoreContext);
  const showSelectedFilters = !store.inOnlinePanelMode();

  return (
    <WithSearch mapContextToProps={({ results, resultSearchTerm }) => ({ results, resultSearchTerm })}>
      {(context: Context) => (
        <div className="sui-layout-main-header">
          <div className="sui-layout-main-header__inner">
            <div className="results-header">
              {!!context.resultSearchTerm ? (
                <h1 data-testid="results-title">
                  <FormattedMessage
                    id="header.resultsWithSearchTerm"
                    values={{ searchTerm: context.resultSearchTerm, linebreak: <br /> }}
                  />
                </h1>
              ) : (
                <h1 data-testid="results-title">
                  <FormattedMessage id="header.results" />
                </h1>
              )}
              {!_.isEmpty(context.results) && <PagingInfo view={CustomPagingInfo} />}
              {showSelectedFilters && <SelectedFilters />}
            </div>
          </div>
        </div>
      )}
    </WithSearch>
  );
});

/**
 * Renders the results part of the result page view
 * @param results list of results
 */
export const Results = ({ results }: { results: Result[] }): JSX.Element => {
  useEffect(() => {
    const resultList = document.getElementById("result-list");
    const isInMobileView = window.innerWidth < 800;

    !!resultList && !isInMobileView && resultList.scrollIntoView({ behavior: "smooth" });
  }, [results]);

  return (
    <WithSearch
      mapContextToProps={({ wasSearched, isLoading }) => ({
        wasSearched,
        isLoading,
      })}
    >
      {(context: Context) => {
        const showResultsAndResultInfo = !context.isLoading && context.wasSearched;

        return (
          <div className="sui-layout-main" id="result-list">
            <ResultsHeader />
            <div className="sui-layout-main-body">{context.isLoading ? <Spinner /> : <ResultsList />}</div>
            {showResultsAndResultInfo && (
              <div className="sui-layout-main-footer">
                <CustomPaging />
              </div>
            )}
          </div>
        );
      }}
    </WithSearch>
  );
};
