import React, { Fragment, useContext } from "react";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { FacetT, FacetValue, Filter, func, Result, WithSearch } from "@elastic/react-search-ui";

import { UIStateStore, UIStateStoreContext } from "../../stores/uiStateStore";
import { FacetTitle } from "./FacetTitle";
import { ShowMoreOptions } from "./ShowMoreOptions";
import { getSortedFacetOptions } from "../../utils/functions";
import { i18nConfig } from "../../i18nConfig";
import { injectIntl, IntlShape } from "react-intl";

const CheckboxOption = injectIntl(
  ({ intl, option, onRemove, onSelect }: { intl: IntlShape; option: FacetValue; onRemove: func; onSelect: func }) => {
    const checked: boolean | undefined = option.selected;

    const optionLabel =
      option.value === "Not version-specific"
        ? (!!i18nConfig.messages[intl.locale] &&
            i18nConfig.messages[intl.locale]["facets.tua.software_version.notVersionSpecific"]) ||
          i18nConfig.messages[intl.defaultLocale]["facets.tua.software_version.notVersionSpecific"]
        : option.value;

    function handleChange() {
      checked ? onRemove(option.value) : onSelect(option.value);
    }

    return (
      <li className="option">
        <label className="checkbox" data-testid="option">
          <span className="checkbox-input" data-testid={`selectFilter-${option.value}`}>
            <input
              type="checkbox"
              name="checkbox"
              value={option.value as string}
              data-testid={`checkbox-${option.value}`}
              checked={checked}
              onChange={handleChange}
            />
            <span className="checkbox-control" />
          </span>
          <span className="checkbox-label" data-testid={`option-label-${option.value}`}>
            {optionLabel}
          </span>
          <WithSearch mapContextToProps={({ results }) => ({ results })}>
            {({ results }: { results: Result[] }) => (
              <Fragment>
                {!_.isEmpty(results) && <span className="facet-result-count small-text">{option.count}</span>}
              </Fragment>
            )}
          </WithSearch>
        </label>
      </li>
    );
  },
);

const FacetContent = observer(
  ({
    labelKey,
    options,
    showMore,
    onRemove,
    onSelect,
    onMoreClick,
  }: {
    labelKey: string;
    options: FacetValue[];
    showMore: boolean;
    onRemove: func;
    onSelect: func;
    onMoreClick: func;
  }): JSX.Element => {
    const uiStore: UIStateStore = useContext(UIStateStoreContext);
    const facetValue: string | undefined = _.find(
      uiStore.getAvailableFacets(),
      (value) => value.label === labelKey,
    )?.field;
    const isOpen: boolean = !!facetValue && uiStore.isFacetOpen(facetValue);

    return (
      <WithSearch mapContextToProps={({ filters, facets }) => ({ filters, facets })}>
        {({ filters, facets }: { filters: Filter[]; facets: Record<string, FacetT[]> }) => (
          <div className="sui-facet" data-testid={`multiSelectFacet-${facetValue}`}>
            <FacetTitle titleKey={labelKey} />
            {isOpen && (
              <Fragment>
                <ul className="options">
                  {_.map(
                    getSortedFacetOptions(facetValue!, options, filters, facets),
                    (option: FacetValue, i: number) => {
                      return <CheckboxOption option={option} onRemove={onRemove} onSelect={onSelect} key={i} />;
                    },
                  )}
                </ul>
                {showMore && <ShowMoreOptions onShowMore={onMoreClick} />}
              </Fragment>
            )}
          </div>
        )}
      </WithSearch>
    );
  },
);

/**
 * Renders the custom multi selection facet.
 * @param label key for translating the name of the facet
 * @param options options for the facet
 * @param onSelect handles selecting an option
 * @param onRemove handles removing an option
 */
export const MultiSelectFacet = ({
  label,
  options,
  showMore,
  onRemove,
  onSelect,
  onMoreClick,
}: {
  label: string;
  options: FacetValue[];
  showMore: boolean;
  onRemove: func;
  onSelect: func;
  onMoreClick: func;
}): JSX.Element => {
  return (
    <FacetContent
      labelKey={label}
      options={options}
      onRemove={onRemove}
      onSelect={onSelect}
      showMore={showMore}
      onMoreClick={onMoreClick}
    />
  );
};
