import axios, { AxiosResponse } from "axios";

interface IATCResponse {
  _identityToken: string;
  Organization_id?: string;
  Organization_resellerId?: string;
}

/**
 * Store for handling authorization.
 */
export class AuthStore {
  /**
   * The organization id of Trimble Solutions, stored to check if user is internal.
   */
  private readonly trimbleSolutionsId = "cba604e8-52f1-48b5-9bb7-be25ce016b34";

  /**
   * Id token of the user retrieved from ATC.
   */
  private idToken?: string;

  /**
   * Stores the information whether the user is internal.
   */
  private isInternal?: boolean;

  /**
   * ATC url, depends on the environment.
   */
  private atcUrl =
    process.env.REACT_APP_ENVIRONMENT === "production" ? "https://account.tekla.com" : "https://account.stg.tekla.com";

  /**
   * Retrieves the identity token from
   * 1) Local storage, or if no token has been stored, from
   * 2) ATC, in which case updates it to local storage
   * @returns identity token or "none" if no user or call to atc fails
   */
  public async getIdToken(): Promise<string> {
    let idToken: string | null | undefined = this.getStoredIdToken();

    if (!idToken) {
      idToken = await this.getIdTokenFromATC();
    }

    return idToken;
  }

  /**
   * Checks whether the current user is an internal user.
   */
  public async isInternalUser(): Promise<boolean> {
    if (this.isInternal !== undefined) {
      return this.isInternal;
    } else {
      const atcInfo = await this.getUserInfoFromATC();

      const hasTrimbleAsOrganizationOrReseller =
        !!atcInfo &&
        ((!!atcInfo.Organization_id && atcInfo.Organization_id === this.trimbleSolutionsId) ||
          (!!atcInfo.Organization_resellerId && atcInfo.Organization_resellerId === this.trimbleSolutionsId));

      this.isInternal = hasTrimbleAsOrganizationOrReseller;

      return hasTrimbleAsOrganizationOrReseller;
    }
  }

  /**
   * Retrieves the identity token from ATC, updates it to local storage.
   * @returns identity token or "none" if call to atc fails
   */
  public async updateIdToken(): Promise<string> {
    try {
      return await this.getIdTokenFromATC();
    } catch (err) {
      console.log(err);
      this.setNoUser();
      return "none";
    }
  }

  private async getIdTokenFromATC(): Promise<string> {
    try {
      const atcResponse: IATCResponse = await this.queryATC("/graph/me?operation=QueryIdentityToken");

      if (!!atcResponse && !!atcResponse._identityToken) {
        this.storeIdToken(atcResponse._identityToken);
        return atcResponse._identityToken;
      } else {
        this.setNoUser();
        return "none";
      }
    } catch (err) {
      console.log("Failed to fetch id token from ATC.");
      this.setNoUser();
      return "none";
    }
  }

  private getStoredIdToken(): string | undefined {
    return this.idToken;
  }

  private async getUserInfoFromATC(): Promise<IATCResponse | undefined> {
    try {
      const userInfo: IATCResponse = await this.queryATC("/graph/me().json");
      return userInfo[0];
    } catch (err) {
      console.log("Failed to fetch user information from ATC.");
    }
  }

  private setNoUser(): void {
    this.idToken = "none";
    this.isInternal = false;
  }

  private storeIdToken(idToken: string): void {
    this.idToken = idToken;
  }

  private async queryATC(query: string): Promise<IATCResponse> {
    const response: AxiosResponse<IATCResponse> = await axios.get(this.atcUrl + query, {
      withCredentials: true,
      validateStatus: (status: number) => {
        return status !== 401;
      },
    });

    if (response.status === 401 || (!!response[0] && response[0].status === 401)) {
      throw new Error("Call to ATC failed.");
    } else {
      return response.data;
    }
  }
}
