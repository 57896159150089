import { Filter, FilterValue } from "@elastic/react-search-ui";
import _ from "lodash";

import config from "../config";
import { FilterTypeEnum, ITermFilter } from "../models/dataModel";
import { UIStateStore } from "../stores/uiStateStore";

function getTermFilterValue(field: string, fieldValue: FilterValue): Record<string, FilterValue> {
  // We do this because if the value is a boolean value, we need to apply
  // our filter differently. We're also only storing the string representation
  // of the boolean value, so we need to convert it to a Boolean.

  // TODO We need better approach for boolean values
  if (fieldValue === "false" || fieldValue === "true") {
    return { [field]: fieldValue === "true" };
  }

  return { [`${field}`]: fieldValue };
}

function getTermFilter(filter: Filter): ITermFilter | undefined {
  if (filter.type === FilterTypeEnum.ANY) {
    return {
      bool: {
        should: filter.values.map((filterValue: FilterValue) => ({
          term: getTermFilterValue(filter.field, filterValue),
        })),
        minimum_should_match: 1,
      },
    };
  } else if (filter.type === FilterTypeEnum.ALL) {
    return {
      bool: {
        filter: filter.values.map((filterValue: FilterValue) => ({
          term: getTermFilterValue(filter.field, filterValue),
        })),
      },
    };
  }
}

export default function buildRequestFilter(
  service: string,
  uiState: UIStateStore,
  filters?: Filter[],
): ITermFilter[] | undefined {
  if (!filters) return;

  const source = uiState.inGlobalSearchMode() ? config.global : config.services[service];

  const allFilters = !!uiState.getOpenCategory()
    ? source.filters.concat(config.services[uiState.getOpenCategory()!].filters)
    : source.filters;

  const termFilters: ITermFilter[] = _.reduce(
    filters,
    (acc: ITermFilter[], filter: Filter): ITermFilter[] => {
      if (allFilters.includes(filter.field)) {
        const termFilter = getTermFilter(filter);
        if (!!termFilter) return [...acc, termFilter];
        else return acc;
      }
      return acc;
    },
    [],
  );

  if (termFilters.length < 1) return;
  return termFilters;
}
