import React, { Fragment, useContext } from "react";
import _ from "lodash";
import { injectIntl, IntlShape } from "react-intl";

import { FilterOptionEnum, FilterTypeEnum, ITag, IResult, IFilter } from "../../models/dataModel";
import { func, WithSearch } from "@elastic/react-search-ui";
import { UIStateStore, UIStateStoreContext } from "../../stores/uiStateStore";
import { i18nConfig } from "../../i18nConfig";

const Tag = injectIntl(({ intl, tag }: { intl: IntlShape; tag: ITag }): JSX.Element => {
  const uiStore: UIStateStore = useContext(UIStateStoreContext);

  function translateNotVersionSpecificLabel(label: string) {
    return label === i18nConfig.messages[intl.defaultLocale]["facets.tua.software_version.notVersionSpecific"]
      ? (!!i18nConfig.messages[intl.locale] &&
        i18nConfig.messages[intl.locale]["facets.tua.software_version.notVersionSpecific"]) ||
      i18nConfig.messages[intl.defaultLocale]["facets.tua.software_version.notVersionSpecific"]
      : label;
  }

  const label =
    !!tag.label &&
    (!!_.isString(tag.label)
      ? tag.label.substring(0, 1).toUpperCase() + tag.label.substring(1)
      : !!_.isArray(tag.label)
        ? translateNotVersionSpecificLabel(tag.label[0])
        : tag.label);

  return (
    <WithSearch mapContextToProps={({ addFilter }) => ({ addFilter })}>
      {({ addFilter }: { addFilter: func }) => {
        function handleClick() {
          if (!uiStore.inOnlinePanelMode()) {
            tag.filters.forEach((filter) => addFilter(filter.field, filter.value, filter.type));
          }
        }

        return (
          <div className="result-tag small-text" data-testid="tag" onClick={handleClick}>
            {label || tag.filters[0].value}
          </div>
        );
      }}
    </WithSearch>
  );
});

const Tags = ({ result }: { result: IResult }): JSX.Element => {
  const uiStore: UIStateStore = useContext(UIStateStoreContext);
  const inOnlinePanelMode: boolean = uiStore.inOnlinePanelMode();
  const showCategoryTags: boolean = uiStore.inGlobalSearchMode() ? !!uiStore.getOpenCategory() : true;
  const tagList: ITag[] = generateTags();

  function generateTags(): ITag[] {
    let tags: ITag[] = [];

    if (!inOnlinePanelMode && showCategoryTags) {
      tags = generateProductTags(result);

      if (!(tags.length > 1) && result.software_version && !_.isEmpty(result.software_version.raw)) {
        tags = generateVersionTags(result, tags);
      }
    }

    if (showCategoryTags && result.type && !_.isEmpty(result.type.raw)) {
      tags.push({
        label: result.type.raw as string,
        filters: [
          {
            field: FilterOptionEnum.TYPE,
            value: result.type.raw as string,
            type: FilterTypeEnum.ANY,
          },
        ],
      });
    }

    if (
      !inOnlinePanelMode &&
      uiStore.inGlobalSearchMode() &&
      result.content_type &&
      !_.isEmpty(result.content_type.raw)
    ) {
      tags.unshift({
        label: result.content_type.raw as string,
        filters: [
          {
            field: FilterOptionEnum.CATEGORY,
            value: result.content_type.raw as string,
            type: FilterTypeEnum.ALL,
          },
        ],
      });
    }

    if (!inOnlinePanelMode && showCategoryTags && result.tags && !_.isEmpty(result.tags.raw)) {
      (result.tags.raw as string[]).forEach((tag: string) => {
        tags.push({
          label: tag,
          filters: [
            {
              field: FilterOptionEnum.TAGS,
              value: tag,
              type: FilterTypeEnum.ALL,
            },
          ],
        });
      });
    }

    return tags;
  }

  function generateProductTags(result: IResult): ITag[] {
    let products: string[] = [];
    const productTags: ITag[] = [];

    if (result.product && !_.isEmpty(result.product.raw)) {
      products = _.isString(result.product.raw)
        ? (result.product.raw as string).split(",")
        : (result.product.raw as string[]);

      if (products.length > 1) {
        products.forEach((product) => {
          productTags.push({
            filters: [
              {
                field: FilterOptionEnum.PRODUCT,
                value: product,
                type: FilterTypeEnum.ALL,
              },
            ],
          });
        });
      } else {
        productTags.push({
          filters: [
            {
              field: FilterOptionEnum.PRODUCT,
              value: products[0],
              type: FilterTypeEnum.ALL,
            },
          ],
        });
      }
    }

    return productTags;
  }

  function generateVersionTags(result: IResult, tags: ITag[]): ITag[] {
    let versions: string[] = _.isString(result.software_version.raw)
      ? (result.software_version.raw as string).split(",")
      : (result.software_version.raw as string[]);
    let notVersionSpecific;

    const versionFilters: IFilter[] = versions.map((version: string) => {
      return {
        field: FilterOptionEnum.VERSION,
        value: version,
        type: FilterTypeEnum.ANY,
      };
    });

    if (!_.isEmpty(tags)) {
      tags = tags.map((tag: ITag) => {
        const products: IFilter[] = tag.filters;
        let label = "";

        if (_.isUndefined(tag.filters) || _.isEmpty(tag.filters)) return tag;

        products.forEach((productFilter: IFilter) => {
          const product: string = productFilter.value;

          if (!["Tekla Structures", "Tekla EPM", "Tekla Structural Designer", "Tekla Tedds"].includes(product)) {
            const productWithoutTekla: string = product.includes("Tekla") ? product.replace("Tekla ", "") : "";
            versions = versions.filter((v: string) => v.includes(product) || v.includes(productWithoutTekla));
          }

          versions = versions
            .map((version) => {
              return version.split(" ").pop() || "";
            })
            .sort();

          if (versions.length > 1) {
            label = `${product}  ${versions.slice(0, 1)} - ${versions.pop()}`;
          } else if (versions.length === 1) {
            if (
              result.software_version.raw &&
              result.software_version.raw[0].toLowerCase() === "not version-specific"
            ) {
              notVersionSpecific = result.software_version.raw as string;
              label = product;
            } else {
              label = `${product}  ${versions}`;
            }
          } else {
            label = product;
          }
        });
        return {
          label: label,
          filters: products.concat(versionFilters),
        };
      });

      !!notVersionSpecific &&
        tags.push({
          label: notVersionSpecific,
          filters: [{ field: FilterOptionEnum.VERSION, value: notVersionSpecific, type: FilterTypeEnum.ANY }],
        });
    } else {
      versionFilters.forEach((version) => {
        tags.push({
          filters: [
            {
              field: FilterOptionEnum.VERSION,
              value: version.value,
              type: FilterTypeEnum.ANY,
            },
          ],
        });
      });
    }

    return tags;
  }

  return (
    <div className="tag-list" data-testid="result-tags">
      {!_.isEmpty(tagList) &&
        _.map(tagList, (tag: ITag, i: number) => {
          return <Tag tag={tag} key={i} />;
        })}
    </div>
  );
};

const URLCrumbs = ({ targetURL }: { targetURL: string }) => {
  function getParts(): string[] {
    const parts = targetURL.substring(8).split("/");
    parts.pop();
    return parts;
  }

  return (
    <Fragment>
      {_.map(getParts(), (crumb: string, i: number) => {
        const lastPart = i + 1 === getParts().length;
        return (
          <span key={i}>
            {crumb}
            {!lastPart && " > "}
          </span>
        );
      })}
    </Fragment>
  );
};

/**
 * Renders a result component.
 * @param result result to display
 */
export const ResultBox = ({ result }: { result: IResult }) => {
  const uiStore: UIStateStore = useContext(UIStateStoreContext);
  const inOnlinePanelMode: boolean = uiStore.inOnlinePanelMode();
  const showTargetURL: boolean = !inOnlinePanelMode && uiStore.inGlobalSearchMode();

  const url = encodeURI(!!result.url && _.isString(result.url.raw) ? (result.url.raw as string) : "");
  const targetURL = decodeURI(new URL(url).searchParams.get("target") || "");

  const teaser: string | undefined =
    !!result.teaser && !!result.teaser.snippet
      ? result.teaser.snippet.trimEnd()
      : !!result.teaser.raw
        ? (result.teaser.raw as string).trimEnd()
        : undefined;
  const styledTeaser = teaser?.endsWith(".") ? teaser : teaser + " ...";

  const showResultBody: boolean = !!teaser || !!result.preview_image_url;

  return (
    <Fragment>
      <div className="sui-result" data-testid="resultItem">
        <div className="result-header">
          {showTargetURL && (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="target-url"
              data-testid="targetUrl"
              title={targetURL || "target-url"}
            >
              <URLCrumbs targetURL={targetURL} />
            </a>
          )}
          <h3>
            <a href={url} target="_blank" rel="noopener noreferrer" data-testid="result-title">
              {result.title.raw}
            </a>
          </h3>
        </div>
        {!inOnlinePanelMode && showResultBody && (
          <div className="result-body">
            {!!styledTeaser && (
              <div className="result-teaser">
                <p dangerouslySetInnerHTML={{ __html: styledTeaser }} data-testid="resultTeaser" />
              </div>
            )}
            {!!result.preview_image_url && (
              <div className="result-preview">
                <img
                  src={result.preview_image_url.raw as string}
                  alt="Preview image"
                  data-testid="resultPreviewImage"
                />
              </div>
            )}
          </div>
        )}
        <div className="result-footer">
          <Tags result={result} />
        </div>
      </div>
      {!inOnlinePanelMode && <hr className="line-between-results" />}
    </Fragment>
  );
};
