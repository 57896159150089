import React, { Fragment, useContext, useEffect } from "react";
import { injectIntl, IntlShape } from "react-intl";
import _ from "lodash";

import { ErrorBoundary, WithSearch, Result, FacetT, Filter, func } from "@elastic/react-search-ui";
import { i18nConfig } from "../i18nConfig";

import { checkIfVersionExists, hasProductBeenSelected, hasVersionBeenSelected } from "../utils/functions";
import { FilterOptionEnum, FilterTypeEnum } from "../models/dataModel";

import { UIStateStore, UIStateStoreContext } from "../stores/uiStateStore";
import { CustomSidebar } from "./sidebar/CustomSidebar";
import { Results } from "./results/Results";
import { Spinner } from "./common/Spinner";
import { ErrorView } from "./common/ErrorView";
import { SearchField } from "./SearchField";

const CustomLayout = injectIntl(
  ({
    intl,
    results,
    wasSearched,
    isLoading,
    facets,
    filters,
    resultSearchTerm,
    addFilter,
  }: {
    intl: IntlShape;
    results: Result[];
    wasSearched: boolean;
    isLoading: boolean;
    facets: Record<string, FacetT[]>;
    filters: Filter[];
    resultSearchTerm: string;
    addFilter: func;
  }): JSX.Element => {
    const uiStore: UIStateStore = useContext(UIStateStoreContext);
    const notVersionSpecific =
      i18nConfig.messages[intl.defaultLocale]["facets.tua.software_version.notVersionSpecific"];

    useEffect(() => {
      if (hasProductBeenSelected(filters)) uiStore.setFacetState(FilterOptionEnum.PRODUCT, true);
      if (hasVersionBeenSelected(filters)) uiStore.setFacetState(FilterOptionEnum.VERSION, true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Add 'Not version-specific' version if a version filter exists when landing on the ResultPage view
    useEffect(() => {
      if (!!checkIfVersionExists(facets, notVersionSpecific) && !!hasVersionBeenSelected(filters)) {
        addFilter(FilterOptionEnum.VERSION, notVersionSpecific, FilterTypeEnum.ANY);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wasSearched]);

    return (
      <div className="sui-layout">
        <div className="sui-layout-body">
          {isLoading && _.isEmpty(results) ? (
            <Spinner />
          ) : (
            <Fragment>
              {wasSearched && resultSearchTerm ? (
                <div className="sui-layout-body__inner">
                  <CustomSidebar />
                  <Results results={results} />
                </div>
              ) : (
                <SearchField filters={filters} />
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  },
);

/**
 * Main component for the search results view.
 */
export const ResultPage = (): JSX.Element => {
  return (
    <div className="trimble-search" id="resultPage" data-testid="result-page">
      <ErrorBoundary view={ErrorView}>
        <WithSearch
          mapContextToProps={({ results, wasSearched, isLoading, facets, filters, resultSearchTerm, addFilter }) => ({
            results,
            wasSearched,
            isLoading,
            facets,
            filters,
            resultSearchTerm,
            addFilter,
          })}
        >
          {(props) => <CustomLayout {...props} />}
        </WithSearch>
      </ErrorBoundary>
    </div>
  );
};
