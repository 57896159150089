import React, { useContext } from "react";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as HideIcon } from "../../assets/icons/remove-collapse-circle.svg";
import { ReactComponent as ShowIcon } from "../../assets/icons/add-expand-circle.svg";

import { UIStateStore, UIStateStoreContext } from "../../stores/uiStateStore";
import { i18nConfig } from "../../i18nConfig";

/**
 * Renders a facet title & the button to show/hide facet options.
 * @param titleKey facet title key, used to get translation
 */
export const FacetTitle = observer(({ titleKey }: { titleKey: string }): JSX.Element => {
  const uiStore: UIStateStore = useContext(UIStateStoreContext);
  const facetValue: string | undefined = _.find(uiStore.getAvailableFacets(), (value) => value.label === titleKey)
    ?.field;
  const isOpen: boolean = !!facetValue && uiStore.isFacetOpen(facetValue);

  function toggleShowOptions() {
    if (!!facetValue) {
      isOpen ? uiStore.setFacetState(facetValue, false) : uiStore.setFacetState(facetValue, true);
    }
  }

  return (
    <div
      className="facet-title"
      data-testid={`facet-title-${useIntl().formatMessage({ id: titleKey })}`}
      onClick={toggleShowOptions}
    >
      <h4>
        <FormattedMessage id={titleKey} defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale][titleKey]} />
      </h4>
      {isOpen ? (
        <HideIcon title={useIntl().formatMessage({ id: "icon.hide.title" })} />
      ) : (
        <ShowIcon title={useIntl().formatMessage({ id: "icon.show.title" })} />
      )}
    </div>
  );
});
